import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { COLUMNS_CATEGORY, COLUMNS_INVITE_CODE } from "../../config/constants";
import {
  requestAddCodeInvite,
  requestDeleteCodeInvite,
  requestGetListCategory,
  requestGetListCodeInvite,
  requestUpdateCodeInvite,
} from "../../service/network/Api";
import { Header } from "../dashboard/component/Header";
import {
  CheckCircleOutlined,
  DeleteFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DateUtil from "../../util/DateUtil";
import { showToast } from "../../util/funcUtils";
import ButtonBottomModal from "../product/component/ButtonBottomModal";

export default function InviteCodeScreen() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [listCodeInvite, setListCodeInvite] = useState([]);
  const [visible, setVisible] = useState({ show: false, type: 0 });
  const [item, setItem] = useState<any>({});
  const [inputCodeInvite, setInputCodeInvite] = useState<any>({
    usages: "",
    code: "",
  });
  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await requestGetListCodeInvite();
      if (res) {
        setIsLoading(false);
        setListCodeInvite(res.data);
      }
    } catch (error) {}
  };

  const handleDeleteCodeInvite = async (id: any) => {
    try {
      const res = await requestDeleteCodeInvite(id);
      if (res) {
        getData();
        showToast("Xoá danh mời thành công!");
      }
    } catch (error) {}
  };
  const handleUpdateCodeInvite = async () => {
    const payloadUpdate = {
      id: item._id,
      body: {
        ...inputCodeInvite,
        usages: +inputCodeInvite.usages,
      },
    };
    const payloadAdd = {
      ...inputCodeInvite,
      usages: +inputCodeInvite.usages,
    };
    // if (!inputCodeInvite?.code || !inputCodeInvite?.usages) {
    //   message.warning("Vui lòng nhập đầy đủ thông tin!");
    // }
    // if (isNaN(inputCodeInvite?.code)) {
    //   message.warning("Mã mời vui lòng nhập số! VD:123456,134256 ");
    // }
    // if (inputCodeInvite?.code?.length > 6) {
    //   message.warning("Mã mời tối đa là 6 ký tự!");
    // }
    try {
      const res =
        visible.type == 1
          ? await requestAddCodeInvite(payloadAdd)
          : await requestUpdateCodeInvite(payloadUpdate);
      if (res) {
        getData();
        setVisible({ show: false, type: 0 });
        showToast(
          visible.type == 1
            ? "Thêm mới mã mời thành công!"
            : "Cập nhật mã mời thành công!"
        );
        setInputCodeInvite({
          code: "",
          usages: "",
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);
  const renderModal = () => {
    return (
      <Modal
        onCancel={() => {
          setVisible({ show: false, type: 0 });
        }}
        maskClosable={false}
        footer={null}
        title={"Mã mời"}
        visible={visible.show}
      >
        <div style={{ marginBottom: 30 }}>
          <Col>
            <label style={{ fontWeight: "bolder" }} children={"Tên mã mời"} />
            <Input
              value={inputCodeInvite.code || ""}
              style={{ marginTop: 8 }}
              placeholder="Nhập tên mã mời"
              onChange={(input) => {
                setInputCodeInvite({
                  ...inputCodeInvite,
                  code: input.target.value,
                });
              }}
            />
          </Col>
          <Col style={{ marginTop: 20 }}>
            <label style={{ fontWeight: "bolder" }} children={"Số lượng"} />
            <Input
              value={inputCodeInvite.usages}
              style={{ marginTop: 8 }}
              placeholder="Nhập số lượng"
              onChange={(input) => {
                setInputCodeInvite({
                  ...inputCodeInvite,
                  usages: input.target.value,
                });
              }}
            />
          </Col>
        </div>

        <ButtonBottomModal
          text={"Xác nhận"}
          isLoadingButton={false}
          onCancel={() => {
            setVisible({ show: false, type: 0 });
            setInputCodeInvite({
              code: "",
              usages: "",
            });
          }}
          onClickconfirm={() => {
            handleUpdateCodeInvite();
          }}
        />
      </Modal>
    );
  };
  return (
    <div style={{ marginTop: 10 }}>
      <PageHeader
        title="Mã mời"
        style={{ backgroundColor: "white", margin: "5px 10px 10px" }}
        extra={[
          <Header
            showButton={true}
            onClick={() => {
              setVisible({ show: true, type: 1 });
            }}
          />,
        ]}
      />
      <div
        style={{
          backgroundColor: "white",
          margin: "0px 10px 0px",
          padding: "15px 20px",
        }}
      >
        <Table
          style={{ height: "90vh" }}
          loading={isLoading}
          dataSource={listCodeInvite}
          bordered
          rowKey={(_: any, index: any) => `${index}`}
          columns={COLUMNS_INVITE_CODE}
          expandRowByClick={true}
          pagination={{}}
          scroll={{ x: "50vh" }}
          expandable={{
            expandedRowRender: (item: any) => (
              <div style={{ backgroundColor: "white" }}>
                <Card
                  style={{
                    width: "100%",
                    backgroundColor: "#f6f9ff",
                    borderColor: "#1890ff",
                    borderTop: "none",
                  }}
                  actions={[
                    <Button
                      onClick={() => {
                        setVisible({ show: true, type: 2 });
                        setItem(item);
                        setInputCodeInvite({
                          usages: item.usages,
                          code: item.code,
                        });
                      }}
                      type="text"
                      size="large"
                      icon={<CheckCircleOutlined color="red" />}
                      style={{
                        color: "#1890ff",
                      }}
                    >
                      Chỉnh sửa thông tin
                    </Button>,
                    <Popconfirm
                      title={"Bạn chắc chắn muốn xoá sản phẩm này không?"}
                      onConfirm={() => {
                        handleDeleteCodeInvite(item._id);
                      }}
                      okText="Xoá"
                      cancelText="Quay lại"
                      okButtonProps={{
                        danger: true,
                        type: "primary",
                      }}
                    >
                      <Button
                        type="text"
                        size="large"
                        danger
                        icon={<DeleteFilled />}
                      >
                        Xoá
                      </Button>
                    </Popconfirm>,
                  ]}
                >
                  <h3
                    style={{
                      color: "#007aff",
                    }}
                    children={"Thông tin mã mời"}
                  />
                  <Row style={{ marginTop: 20 }}>
                    <Col flex={1}>
                      <h4>Tên mã mời: {item.code}</h4>
                      <h4>Số lượng: {item.usages}</h4>
                    </Col>
                    <Col flex={1}>
                      <h4>
                        Ngày tạo:{" "}
                        {DateUtil.formatTimeDateReview(item.created_at)}
                      </h4>
                    </Col>
                  </Row>
                </Card>
              </div>
            ),
          }}
        />
      </div>
      {renderModal()}
    </div>
  );
}
