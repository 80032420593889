import {
  CheckCircleOutlined,
  DeleteFilled,
  LoadingOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import R from "../../component/assets";
import { COLUMNS_CATEGORY, getBase64, UNIT } from "../../config/constants";
import { ADMIN_ROUTER_PATH } from "../../config/router";
import reactotron from "../../ReactotronConfig";
import { URL_IMAGE } from "../../service/ApiService";
import {
  requestAddCategory,
  requestAddProduct,
  requestAddProductToVip,
  requestDeleteCategory,
  requestGetListCategory,
  requestGetListProduct,
  requestUpdateCategory,
  requestUploadImage,
} from "../../service/network/Api";
import DateUtil from "../../util/DateUtil";
import { formatPrice, showToast } from "../../util/funcUtils";
import { Header } from "../dashboard/component/Header";
import ButtonBottomModal from "../product/component/ButtonBottomModal";

const { Option } = Select;

const CategoryScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [fromDaytoDay, setFromDaytoDay] = useState<any>([]);
  const [search, setSearch] = useState(undefined);
  const [visible, setVisible] = useState(0);
  const [listProduct, setListProduct] = useState<any>([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [idProduct, setIdProduct] = useState<any>();
  const [loadMore, setLoadMore] = useState<any>(false);
  const [totalPage, setTotalPage] = useState<any>(undefined);
  const [page, setPage] = useState<any>(1);
  const [upload, setUpload] = useState<any>({
    loading: false,
    imageUrl: "",
  });
  const [payloadAdd, setPayloadAdd] = useState<any>({
    name: "",
    image: "",
    value_commission: "",
  });
  const navigate = useNavigate();
  const [item, setItem] = useState<any>({});
  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Cho phép ảnh có dung lượng tối đa là 2MB");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleDeleteCategory = async (id: any) => {
    try {
      const res = await requestDeleteCategory(id);
      if (res) {
        getData();
        showToast("Xoá danh mục thành công!");
      }
    } catch (error) {}
  };

  const getListProduct = async (PAGE: number) => {
    setLoadMore(true);
    const payload = {
      page: PAGE,
      limit: undefined,
      from: undefined,
      to: undefined,
      name: undefined,
      sort_by: undefined,
    };
    try {
      const res = await requestGetListProduct(payload);
      reactotron.logImportant!("LIST_PRODUCT", res);
      if (res) {
        setLoadMore(false);
        setTotalPage(res.meta.pagination.total_pages);
        if (page == 1) setListProduct(res.data);
        if (page > 1) {
          let data = listProduct;
          data = data.concat(res.data);
          setListProduct(data);
        }
      }
    } catch (error) {}
  };

  const handleChange = (info: any) => {
    reactotron.logImportant!("info", info);
    if (info.file.status === "uploading") {
      let length = info.fileList.length - 1;
      setPayloadAdd({
        ...payloadAdd,
        image: info.fileList[length].originFileObj,
      });
      console.log("info", info);
      setUpload({
        imageUrl: "",
        loading: true,
      });
      return;
    }

    if (info.file.status === "done" || info.file.status === "error") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        return setUpload({
          imageUrl: imageUrl,
          loading: false,
        });
      });
    }
  };

  const handleAddProduct = async () => {
    const payload = {
      name: idProduct.name,
      image: idProduct.image,
      category_id: item?._id,
      price: +idProduct.price,
    };
    try {
      const res = await requestAddProduct(payload);
      if (res) {
        showToast("Thêm sản phẩm thành công!");
        setVisible(0);
        getData();
        setIdProduct({});
      }
    } catch (error) {
      setIdProduct({});
    } finally {
      // handleCancelModal();
    }
  };

  const handleAddCategory = async (visible: number) => {
    let formData = new FormData();
    reactotron.logImportant!("payloadAdd", payloadAdd);
    if (visible == 1 && upload.imageUrl.includes("uploads")) {
      let payloadUpdate = {
        id: item._id,
        body: {
          name: payloadAdd.name || item.name,
          image: upload.imageUrl.slice(URL_IMAGE.length + 1),
          value_commission:
            +payloadAdd.value_commission || item.value_commission,
        },
      };
      const res = await requestUpdateCategory(payloadUpdate);
      if (res) {
        showToast("Cập nhật thông tin thành công!");
        getData();
        setVisible(0);
        setPayloadAdd("");
        setUpload("");
        setItem("");
      }
      return;
    }
    formData.append("file", payloadAdd?.image);
    // return;
    try {
      const responseImage = await requestUploadImage(formData);

      if (responseImage) {
        if (visible == 1) {
          let payloadUpdate = {
            id: item._id,
            body: {
              name: payloadAdd.name || item.name,
              image: responseImage.data.path,
              value_commission:
                +payloadAdd.value_commission || item.value_commission,
            },
          };
          const res = await requestUpdateCategory(payloadUpdate);
          if (res) {
            showToast("Cập nhật thông tin thành công!");
            getData();
            setVisible(0);
            setPayloadAdd("");
            setUpload("");
            setItem("");
          }
          return;
        }
        if (visible == 2) {
          const res = await requestAddCategory({
            name: payloadAdd.name,
            image: responseImage.data.path,
          });
          if (res) {
            showToast("Thêm danh mục thành công!");
            getData();
            setVisible(0);
            setPayloadAdd("");
            setUpload("");
            setItem("");
          }
          return;
        }
      }
    } catch (error) {
      showToast("Đã có lỗi xảy ra!");
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const payload = {
      page: 1,
      limit: 1000,
      from: fromDaytoDay[0],
      to: fromDaytoDay[1],
      name: search,
    };
    try {
      const res = await requestGetListCategory(payload);
      if (res) {
        setIsLoading(false);
        setListCategory(res.data);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    getData();
  }, [search, fromDaytoDay]);

  React.useEffect(() => {
    getListProduct(page);
  }, [page]);

  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderTable = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
          margin: "0px 10px 0px",
          padding: "15px 20px",
        }}
      >
        <Table
          style={{ height: "90vh" }}
          loading={isLoading}
          dataSource={listCategory}
          bordered
          rowKey={(_: any, index: any) => `${index}`}
          columns={COLUMNS_CATEGORY}
          expandRowByClick={true}
          pagination={{}}
          scroll={{ x: "50vh" }}
          expandable={{
            expandedRowRender: (item: any) => (
              <div style={{ backgroundColor: "white" }}>
                <Card
                  style={{
                    width: "100%",
                    backgroundColor: "#f6f9ff",
                    borderColor: "#1890ff",
                    borderTop: "none",
                  }}
                  actions={[
                    <Button
                      onClick={() => {
                        setVisible(1);
                        setItem(item);
                        setUpload({
                          ...upload,
                          imageUrl: URL_IMAGE + "/" + item.image,
                        });
                        setPayloadAdd({
                          ...payloadAdd,
                          value_commission: item?.value_commission,
                        });
                      }}
                      type="text"
                      size="large"
                      icon={<CheckCircleOutlined color="red" />}
                      style={{
                        color: "#1890ff",
                      }}
                    >
                      Chỉnh sửa thông tin
                    </Button>,
                    <Button
                      onClick={() => {
                        setItem(item);
                        setVisible(3);
                      }}
                      type="text"
                      size="large"
                      icon={<PlusCircleOutlined color="red" />}
                      style={{
                        color: "green",
                      }}
                    >
                      Thêm sản phẩm
                    </Button>,
                    <Button
                      onClick={() => {
                        navigate({
                          pathname: `${ADMIN_ROUTER_PATH.LIST_PRODUCT_VIP}`,
                          search: createSearchParams({
                            key: item._id,
                          }).toString(),
                        });
                      }}
                      type="text"
                      size="large"
                      icon={<OrderedListOutlined color="red" />}
                      style={{
                        color: "GrayText",
                      }}
                    >
                      Danh sách sản phẩm
                    </Button>,
                    <Popconfirm
                      title={"Bạn chắc chắn muốn xoá sản phẩm này không?"}
                      onConfirm={() => {
                        handleDeleteCategory(item._id);
                      }}
                      okText="Xoá"
                      cancelText="Quay lại"
                      okButtonProps={{
                        danger: true,
                        type: "primary",
                      }}
                    >
                      <Button
                        type="text"
                        size="large"
                        danger
                        icon={<DeleteFilled />}
                      >
                        Xoá
                      </Button>
                    </Popconfirm>,
                  ]}
                >
                  <h3
                    style={{
                      color: "#007aff",
                    }}
                    children={"Thông tin danh mục"}
                  />
                  <Row style={{ marginTop: 20 }}>
                    <Col flex={1}>
                      <h4>Tên danh mục: {item.name}</h4>
                      <h4>Ảnh danh mục:</h4>
                      <img
                        crossOrigin="anonymous"
                        src={URL_IMAGE + "/" + item.image}
                        style={{ width: 150, height: 150 }}
                      />
                    </Col>
                    <Col flex={1}>
                      <h4>Hoa hồng: {item.value_commission + "%"}</h4>
                      <h4>
                        Ngày tạo:{" "}
                        {DateUtil.formatTimeDateReview(item.created_at)}
                      </h4>
                    </Col>
                  </Row>
                </Card>
              </div>
            ),
          }}
        />
      </div>
    );
  };
  const renderModal = () => {
    return (
      <Modal
        onCancel={() => {
          setVisible(0);
        }}
        maskClosable={false}
        footer={null}
        title={
          visible == 1
            ? "Cập nhật thông tin danh mục"
            : visible == 2
            ? "Thêm mới danh mục"
            : "Thêm mới sản phẩm"
        }
        visible={visible == 1 || visible == 2 || visible == 3}
      >
        {visible == 3 && (
          <div
            style={{
              marginBottom: 30,
              width: 500,
            }}
          >
            <Col style={{ width: "80%" }}>
              <label
                style={{ fontWeight: "bolder", color: "GrayText" }}
                children={"Sản phẩm"}
              />
              <Select
                value={idProduct?.name || undefined}
                allowClear
                placeholder={"Chọn sản phẩm"}
                style={{ width: "100%", marginTop: 8 }}
                onChange={(value) => {
                  setIdProduct(listProduct[value]);
                }}
                onPopupScroll={(event: any) => {
                  let isEndOfList =
                    event.target.scrollTop >= 0.8 * event.target.scrollHeight;
                  if (isEndOfList && page != totalPage) {
                    if (!loadMore) {
                      setPage((prev: any) => (prev = page + 1));
                      return;
                    }
                    setPage(page);
                  }
                }}
              >
                {listProduct.map((item: any, index: number) => {
                  return (
                    <Option value={index}>
                      {
                        <Col>
                          <h3>
                            [{index + 1}]{item.name}
                          </h3>
                          <h4 style={{ color: "red" }}>
                            {"Giá:" + formatPrice(item.price) + UNIT}
                          </h4>
                        </Col>
                      }
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col style={{ width: "80%", marginTop: 15 }}>
              <label
                style={{ fontWeight: "bolder", color: "GrayText" }}
                children={"Giá"}
              />
              <Input
                disabled
                value={formatPrice(idProduct?.price || 0) + UNIT}
                style={{ marginTop: 8 }}
                placeholder=""
              />
            </Col>

            <Col style={{ width: "60%", marginTop: 15 }}>
              <div>
                <label
                  style={{ fontWeight: "bolder", color: "GrayText" }}
                  children={"Ảnh sản phẩm"}
                />
              </div>
              <img
                crossOrigin="anonymous"
                alt="product"
                src={
                  idProduct?.image
                    ? `${URL_IMAGE}/${idProduct?.image}`
                    : R.images.img_image_empty
                }
                style={{
                  width: 150,
                  height: 150,
                  marginTop: 10,
                  resize: "vertical",
                }}
              />
            </Col>
          </div>
        )}
        {visible == 2 ||
          (visible == 1 && (
            <div style={{ marginBottom: 30 }}>
              <Col>
                <label
                  style={{ fontWeight: "bolder" }}
                  children={"Tên danh mục"}
                />
                <Input
                  value={payloadAdd.name || item.name}
                  style={{ marginTop: 8 }}
                  placeholder="Nhập tên danh mục"
                  onChange={(input) => {
                    setPayloadAdd({
                      ...payloadAdd,
                      name: input.target.value,
                    });
                  }}
                />
              </Col>
              <Col style={{ marginTop: 15 }}>
                <label style={{ fontWeight: "bolder" }} children={"Hoa hồng (VD: 0.25, 0.1, 1, 1.0, ....)"} />
                <Input
                  value={payloadAdd.value_commission}
                  style={{ marginTop: 8 }}
                  placeholder="Nhập % hoa hồng"
                  onChange={(input) => {
                    setPayloadAdd({
                      ...payloadAdd,
                      value_commission: input.target.value,
                    });
                  }}
                />
              </Col>
              <Col style={{ width: "80%", marginTop: 15 }}>
                <label style={{ fontWeight: "bolder" }} children={"Ảnh"} />
                <div style={{ marginTop: 10 }}>
                  <Upload
                    name="icon_url"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {upload.imageUrl ? (
                      <img
                        crossOrigin="anonymous"
                        src={upload.imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          height: "100%",
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
              </Col>
            </div>
          ))}

        <ButtonBottomModal
          text={"Xác nhận"}
          isLoadingButton={false}
          onCancel={() => {
            setVisible(0);
          }}
          onClickconfirm={() => {
            if (visible == 3) {
              handleAddProduct();
              return;
            }
            handleAddCategory(visible);
          }}
        />
      </Modal>
    );
  };
  return (
    <div style={{ marginTop: 10 }}>
      <PageHeader
        title="Danh mục"
        style={{ backgroundColor: "white", margin: "5px 10px 10px" }}
        extra={[
          <Header
          // showButton={true}
          // onClick={() => {
          //   setVisible(2);
          // }}
          // onSearchSubmit={(input: any) => {
          //   setSearch(input);
          // }}
          // isSearchLoading={isSearchLoading}
          // fromDaytoDay={fromDaytoDay}
          // dateOnSubmit={(x: string, y: string) => {
          //   setFromDaytoDay([x, y]);
          // }}
          // placeholderSearch={"Nhập tên hoặc mã danh mục"}
          />,
        ]}
      />
      {renderTable()}
      {renderModal()}
    </div>
  );
};

export default CategoryScreen;
