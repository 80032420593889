import { PageHeader, Tabs } from "antd";
import { useState } from "react";
import InfoBankScreen from "./InfoBankScreen";
import SetBackgroundImage from "./SetBackgroundImage";
// import LikeScreen from "./LikeScreen";
const { TabPane } = Tabs;

const ConfigScreen = () => {
  const [tabs, setTabs] = useState(1);
  const onChange = (key: string) => {
    setTabs(+key);
  };
  return (
    <div>
      <PageHeader
        title="Cấu hình"
        style={{ backgroundColor: "white", margin: "5px 10px 10px" }}
      />
      <div
        style={{
          backgroundColor: "white",
          margin: "0px 10px 0px",
          padding: "15px 20px",
        }}
      >
        <Tabs onChange={onChange} type="card">
          {/* <TabPane tab="Tài khoản" key="1">
            <UserInfoScreen />
          </TabPane> */}
          <TabPane tab="Link CSKH" key="2">
            <InfoBankScreen />
          </TabPane>
          {/* <TabPane tab="Ngân hàng" key="2">
            <InfoBankScreen />
          </TabPane> */}
          <TabPane tab="Cài ảnh nền" key="3">
            <SetBackgroundImage />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ConfigScreen;
