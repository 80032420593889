import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "antd";
import Icon, {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background-color: white;
`;
type ButtonBottomModalProps = {
  isLoadingButton: boolean;
  showActionDelete?: boolean;
  onCancel: any;
  text?: string;
  onClickconfirm?: any;
  onDelete?: any;
};

const ButtonBottomModal = ({
  isLoadingButton,
  onCancel,
  text,
  onClickconfirm,
  showActionDelete,
  onDelete,
}: ButtonBottomModalProps) => {
  return (
    <Row gutter={16} justify="end" style={{ marginTop: 30 }}>
      <Col>
        <Button
          style={{ fontWeight: 800, borderRadius: "5px", height: "35px" }}
          danger
          onClick={() => {
            // form.resetFields()
            onCancel();
          }}
        >
          <CloseCircleOutlined />
          Huỷ
        </Button>
      </Col>
      {showActionDelete ? (
        <Col>
          <Button
            onClick={() => {onDelete()}}
            type="primary"
            loading={isLoadingButton}
            htmlType="submit"
            style={{
              fontWeight: 800,
              borderRadius: "5px",
              backgroundColor: "red",
              height: "35px",
            }}
          >
            <DeleteOutlined />
            Xoá cấu hình
          </Button>
        </Col>
      ) : null}
      <Col>
        <Button
          onClick={() => onClickconfirm()}
          type="primary"
          loading={isLoadingButton}
          htmlType="submit"
          style={{
            fontWeight: 800,
            borderRadius: "5px",
            backgroundColor: "#00abba",
            borderColor: "#00abba",
            height: "35px",
          }}
        >
          <CheckCircleOutlined />
          {text}
        </Button>
      </Col>
    </Row>
  );
};

export default ButtonBottomModal;
