import I18n from './translation'
function strings(){
    return{
    account: I18n.t('account'),
    password: I18n.t('password'),
    login: I18n.t('login'),
    partner: I18n.t('partner'),
    remember_password: I18n.t('remember_password'),
    dialog_error: I18n.t('dialog.error'),
    dialog_cancel: I18n.t('dialog.cancel'),
    dialog_warning: I18n.t('dialog.warning'),
    dialog_info: I18n.t('dialog.info'),
    dialog_loading: I18n.t('dialog.loading'),
    dialog_select: I18n.t('dialog.select'),
    dialog_success: I18n.t('dialog.success'),
    product_category: I18n.t('product_category'),
    category: I18n.t('category'),
    enterprise: I18n.t('enterprise'),
    request: I18n.t('request'),
    banner: I18n.t('banner'),
    config: I18n.t('config'),
    created_at: I18n.t('created_at'),
    time_minus_hour_ago: I18n.t('time_minus.hour_ago'),
    time_minus_minute_ago: I18n.t('time_minus.minute_ago'),
    time_minus_just_now: I18n.t('time_minus.just_now'),
    time_minus_day_ago: I18n.t('time_minus.day_ago'),
    fail_request: I18n.t('fail_request'),
    error_network: I18n.t('error_network'),
    please_enter_category_name: I18n.t('please_enter.category_name'),
    please_enter_account: I18n.t('please_enter.account'),
    please_enter_pass: I18n.t('please_enter.pass'),
    please_select_category_name: I18n.t('please_select.category_name'),
    store: I18n.t('store'),
    payment_info: I18n.t('payment.info'),
    payment_method: I18n.t('payment.method'),
    search_category: I18n.t('search.category'),
    search_product: I18n.t('search.product'),
    search_order: I18n.t('search.order'),
    action_create: I18n.t('action.create'),
    action_remove: I18n.t('action.remove'),
    action_edit: I18n.t('action.edit'),
    action_export: I18n.t('action.export'),
    action_import: I18n.t('action.import'),
    action_update: I18n.t('action.update'),
    action_back: I18n.t('action.back'),
    remove_product: I18n.t('remove.product'),
    status: I18n.t('status'),
    active: I18n.t('active'),
    un_active: I18n.t('un_active'),
    success: I18n.t('success'),
    notification: I18n.t('notification'),
    enter: I18n.t('enter'),
    agent_request: I18n.t('agent_request'),
    withdraw_request: I18n.t('withdraw_request'),
    title_header_dashboard: I18n.t('title_header.dashboard'),
    title_header_product: I18n.t('title_header.product'),
    title_header_store: I18n.t('title_header.store'),
    title_header_store_list: I18n.t('title_header.store_list'),
    title_header_inventory: I18n.t('title_header.inventory'),
    title_header_import_stock: I18n.t('title_header.import_stock'),
    title_header_export_stock: I18n.t('title_header.export_stock'),
    title_header_order_list: I18n.t('title_header.order_list'),
    title_header_sell: I18n.t('title_header.sell'),
    title_header_customer: I18n.t('title_header.customer'),
    title_header_customer_list: I18n.t('title_header.customer_list'),
    title_header_account: I18n.t('title_header.account'),
    title_header_import_goods: I18n.t('title_header.import_goods'),
    title_header_agent: I18n.t('title_header.agent'),
    title_header_request_become_partner: I18n.t('title_header.request_become_partner'),
    title_header_order: I18n.t('title_header.order'),
    title_header_news: I18n.t('title_header.news'),
    title_header_debt_report: I18n.t('title_header.debt_report'),
    title_header_shop_report: I18n.t('title_header.shop_report'),
    title_header_config: I18n.t('title_header.config'),
    title_header_ware_house: I18n.t('title_header.ware_house'),
    title_header_sale_report: I18n.t('title_header.sale_report'),
    title_header_stock: I18n.t('title_header.stock'),
    title_header_category_product: I18n.t('title_header.category_product'),
    title_header_report: I18n.t('title_header.report'),
    test: I18n.t('test')
}}
export default strings
        