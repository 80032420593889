import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Checkbox,
    Col,
    Input,
    message,
    Row,
    Select,
    Spin,
    Upload,
} from "antd";
import { useEffect, useState } from "react";
import { getBase64, IS_ACTIVE } from "../../config/constants";
import reactotron from "../../ReactotronConfig";
import { URL_IMAGE } from "../../service/ApiService";
import {
    requestGetListMethod,
    requestUpdateMethod,
} from "../../service/network/Api";
import {
    requestUpdateConfigLinkBackground,
    requestGetLinkBackground,
    requestUploadImage,
} from "../../service/network/Api";
import { showToast } from "../../util/funcUtils";

const SetBackgroundImage = () => {
    const [loading, setLoading] = useState(false);
    const [imageUpload, setImageUpload] = useState<any>();
    
    const [upload, setUpload] = useState<any>({
        loading: false,
        imageUrl: "",
    });
    
    const [linkBg, setLinkBg] = useState<any>('');

    const getLinkBg = async () => {
        try {
            const res = await requestGetLinkBackground('');
            let bg = res?.data?.value || '';
            setUpload({
                loading: false,
                imageUrl: URL_IMAGE+'/'+bg,
            });

        } catch (error) { }
    };


    const handleUpdateBg = async () => {
        try {
            let payload;

            setLoading(true);
            if (imageUpload) {
                let formData = new FormData();
                formData.append("file", imageUpload);
                const res = await requestUploadImage(formData);
                if (res) {
                    payload = {
                        body: { link_background: res.data.path || "" },
                    };

                    const response = await requestUpdateConfigLinkBackground(payload);
                    if (response) {
                        setLoading(false);
                        getLinkBg();
                        showToast("Cập nhật thành công!");
                    }
                }
                return;
            }else{
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
            showToast("Đã có lỗi xảy ra! Vui lòng thử lại.");
        }
    };
    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Cho phép ảnh có dung lượng tối đa là 2MB");
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info: any, type: any) => {
        reactotron.logImportant!("info", info);
        if (info.file.status === "uploading") {
            setUpload({
                imageUrl: "",
                loading: true,
            });
            return;
        }

        if (info.file.status === "done" || info.file.status === "error") {
            // Get this url from response in real world.

            getBase64(info.file.originFileObj, (imageUrl: any) => {
                setImageUpload(info.fileList[0].originFileObj);
                return setUpload({
                    imageUrl: imageUrl,
                    loading: false,
                });

            });
        }
    };

    useEffect(() => {
        getLinkBg();
    }, []);

    const uploadButton = (
        <div>
            {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Spin style={{}} size={"large"} spinning={loading} tip="Đang xử lý...">
            <div style={{ display: "flex", height: "80vh" }}>
                <Col style={{}}>
                    <Row style={{ justifyContent: "space-between" }}>
                        <div style={{ marginTop: 15 }}>
                            <h4 style={{ marginBottom: 10 }}>Ảnh nền trang chủ</h4>
                            <p>- Bấm vào khung ảnh để chọn ảnh mới</p>
                            <p>- Kích thước phù hợp: 750px * 1334px</p>
                            <Upload
                                name="icon_url"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload}
                                onChange={(info) => handleChange(info, "QR")}
                            >
                                {upload.imageUrl ? (
                                    <img
                                        crossOrigin="anonymous"
                                        src={upload.imageUrl}
                                        alt="avatar"
                                        style={{
                                            width: "100%",
                                            objectFit: "contain",
                                            height: "100%",
                                        }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>
                    </Row>

                    <Row
                        style={{
                            width: "150%",
                            marginTop: 20,
                            //   justifyContent: "center",
                        }}
                    >
                        <Button
                            onClick={handleUpdateBg}
                            style={{
                                fontWeight: 800,
                                borderRadius: "3px",
                            }}
                            type="primary"
                            htmlType="submit"
                            children={"Cập nhật"}
                        />
                    </Row>
                </Col>
            </div>
        </Spin>
    );
};

export default SetBackgroundImage;
