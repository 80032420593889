import {
  Button,
  Col,
  Input,
  Row,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import {
  requestUpdateConfigLinkCskh,
  requestGetLinkCskh,
} from "../../service/network/Api";
import { showToast } from "../../util/funcUtils";

const InfoBankScreen = () => {
  const [linkCskh, setLinkCskh] = useState<any>('');
  const [loading, setLoading] = useState(false);

  const getLinkCskh = async () => {
    try {
      const res = await requestGetLinkCskh('');
      let cskh = res?.data?.value || '';
      setLinkCskh(cskh);

    } catch (error) { }
  };


  const handleUpdateConfig = async () => {
    try {
      let payload;

      setLoading(true);

      payload = {
        body: { link_cskh: linkCskh.trim() }
      };

      const response = await requestUpdateConfigLinkCskh(payload);
      if (response) {
        setLoading(false);
        showToast("Cập nhật thành công!");
      } else {
        setLoading(false);
        showToast("Đã có lỗi xảy ra! Vui lòng thử lại!");
      }

    } catch {
      setLoading(false);
      showToast("Đã có lỗi xảy ra! Vui lòng thử lại.");
    }
  }

  useEffect(() => {
    getLinkCskh();
  }, []);

  return (
    <Spin style={{}} size={"large"} spinning={loading} tip="Đang xử lý...">
      <div style={{ display: "flex", height: "80vh" }}>
        <Col style={{}}>
          <Col style={{ marginTop: 15 }}>
            <label
              style={{ fontWeight: "bolder", color: "GrayText" }}
              children={"Nhập link CSKH"}
            />
            <Input
              value={linkCskh}
              style={{ width: "150%", marginTop: 8 }}
              placeholder="Nhập link CSKH"
              onChange={(input) => {
                setLinkCskh(input.target.value);
              }}
            />
          </Col>

          <Row
            style={{
              width: "150%",
              marginTop: 20,
              justifyContent: "left",
            }}
          >
            <Button
              onClick={handleUpdateConfig}
              style={{
                fontWeight: 700,
                borderRadius: "3px",
              }}
              type="primary"
              htmlType="submit"
              children={"Cập nhật"}
            />
          </Row>
        </Col>
      </div>
    </Spin>
  );
};

export default InfoBankScreen;
