import { Col, PageHeader, Row } from "antd";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  convertVndToDollar,
  DOLLARS,
  handleConvertValueStatus,
  UNIT,
} from "../../config/constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import reactotron from "../../ReactotronConfig";
import {
  requestGetStatistic,
  requestGetStatisticCustomer,
} from "../../service/network/Api";
import { formatPrice } from "../../util/funcUtils";
import { Header } from "../dashboard/component/Header";

export default function StatisticalScreen() {
  const [status, setStatus] = useState<any>(1);
  const [totalTopUp, setTotalTopUp] = useState<any>();
  const [statisticCus, setStatisticCus] = useState<any>();
  const [totalCashOut, setTotalCashOut] = useState<any>();
  const [fromDaytoDay, setFromDaytoDay] = useState<any>([]);
  const { width, height } = useWindowDimensions();

  const getData = async (type: any) => {
    let payload = {
      from: fromDaytoDay[0] || "",
      to: fromDaytoDay[1] || "",
      user_id: "",
      status: handleConvertValueStatus(status),
      type: type,
    };
    try {
      const res = await requestGetStatistic(payload);
      if (res) {
        if (type == "TopUp") {
          setTotalTopUp(res.data.total);
        }
        if (type == "CashOut") {
          setTotalCashOut(res.data.total);
        }
      }
    } catch (error) {}
  };
  const getStaticCustomer = async (type: any) => {
    let payload = {
      from: fromDaytoDay[0] || "",
      to: fromDaytoDay[1] || "",
      user_id: "",
      status: handleConvertValueStatus(status),
    };
    try {
      const res = await requestGetStatisticCustomer(payload);
      if (res) {
        setStatisticCus(res.data);
        reactotron.logImportant!("res", res);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData("TopUp");
    getData("CashOut");
    getStaticCustomer("TopUp");
    getStaticCustomer("CashOut");
  }, [status, fromDaytoDay]);

  const renderItem = (title: string, price: any, color: any) => {
    return (
      <Col>
        <div
          style={{
            marginLeft: "1vh",
            backgroundColor: color,
            height: 150,
            width: "50vh",
            borderRadius: 10,
            boxShadow: "1px 3px 1px #DDD",
            paddingTop: 5,
            marginTop: 10,
          }}
        >
          <Row>
            <h1 style={{ color: "white", marginLeft: 10 }}>{title}</h1>
          </Row>
          <div style={{ paddingLeft: 30 }}>
            <h1
              style={{ color: "white" }}
              children={`${formatPrice(price) || 0}`}
            />
          </div>
        </div>
      </Col>
    );
  };

  return (
    <div style={{ marginTop: 10 }}>
      <PageHeader
        title="Thông kê"
        style={{ backgroundColor: "white", margin: "5px 10px 10px" }}
        extra={[
          <Header
            onStatusSubmit={(statusKey: string) => {
              console.log!("statusKey", statusKey);
              if (!status) {
                setStatus(1);
                return;
              }
              setStatus(statusKey + 1);
            }}
            // onClear={() => {
            //   setStatus(1);
            // }}
            placeholderDrop={"Trạng thái"}
            dataDropdown={[
              {
                name: "Chờ phê duyệt",
              },
              {
                name: "Đã phê duyệt",
              },
              {
                name: "Từ chối",
              },
            ]}
            fromDaytoDay={fromDaytoDay}
            dateOnSubmit={(x: string, y: string) => {
              setFromDaytoDay([x, y]);
            }}
          />,
        ]}
      />
      <div
        style={{
          backgroundColor: "white",
          margin: 10,
          // padding: "15px 20px",
          height: "90vh",
        }}
      >
        <h1 style={{ color: "black", marginLeft: 10 }}>
          {status == 1
            ? "Chờ phê duyệt"
            : status == 2
            ? "Đã phê duyệt"
            : "Từ chối"}
        </h1>
        <Col>
          <Row
            style={
              {
                // backgroundColor: "red",
                // justifyContent: "space-between",
              }
            }
          >
            <div
              style={{
                marginLeft: "1vh",
                backgroundColor: "#3AB4F2",
                height: 150,
                // width: width * 0.25,
                width: "50vh",
                borderRadius: 10,
                boxShadow: "1px 3px 1px #DDD",
                paddingTop: 5,
                marginTop: 10,
              }}
            >
              <Row>
                <h1 style={{ color: "white", marginLeft: 10 }}>
                  Tổng yêu cầu nạp
                </h1>
              </Row>
              <div style={{ paddingLeft: 30 }}>
                <h1
                  style={{ color: "white" }}
                  children={`${formatPrice(totalTopUp) || 0}${UNIT}`}
                />
              </div>
            </div>
            {renderItem(
              "Chênh lệch gửi - rút",
              (totalCashOut - totalTopUp || 0) + UNIT,
              "#CBA0AE"
            )}
            {renderItem(
              "Số người đăng ký thêm",
              statisticCus?.totalUserRegisted || 0,
              "#2B4865"
            )}
            {/* {renderItem(
              "Số người nạp lần đầu",
              statisticCus?.totalUserTopupFirstTime
            )}
            {renderItem("Số người nạp", statisticCus?.totalUserTopup)} */}
          </Row>
          <Row style={{}}>
            <div
              style={{
                marginLeft: "1vh",
                backgroundColor: "#D61C4E",
                height: 150,
                width: "50vh",
                borderRadius: 10,
                boxShadow: "1px 3px 1px #DDD",
                paddingTop: 5,
                marginTop: 10,
              }}
            >
              <Row>
                <h1 style={{ color: "white", marginLeft: 10 }}>
                  Tổng yêu cầu rút
                </h1>
              </Row>
              <div style={{ paddingLeft: 30 }}>
                <h1
                  style={{ color: "white" }}
                  children={`${formatPrice(totalCashOut) || 0}${UNIT}`}
                />
                {/* <NumberFormat
                  value={totalCashOut}
                  className="foo"
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                /> */}
              </div>
            </div>
            {renderItem(
              "Số người nạp lần đầu",
              statisticCus?.totalUserTopupFirstTime,
              "#F29393"
            )}
            {renderItem(
              "Số người nạp",
              statisticCus?.totalUserTopup,
              "#0F3D3E"
            )}
          </Row>
        </Col>
      </div>
    </div>
  );
}
