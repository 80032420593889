import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Card, PageHeader, Table } from "antd";
import { useEffect, useState } from "react";
import {
  requestGetListLikeMessage,
  requestSuccessLikeMessage,
} from "../../service/network/Api";
import DateUtil from "../../util/DateUtil";
import { showToast } from "../../util/funcUtils";
import { Header } from "../dashboard/component/Header";

export const COLUMNS_LIKE = [
  {
    title: "Mã khách hàng",
    dataIndex: "user",
    key: "user",
    render: (value: any, record: any) => <h4>{record.user._id}</h4>,
  },
  {
    title: "SĐT",
    dataIndex: "phone",
    key: "phone",
    render: (value: any, record: any) => <h4>{record.user.phone}</h4>,
  },
  {
    title: "Nội dung",
    dataIndex: "text",
    key: "text",
    render: (value: any) => <h4>{value}</h4>,
  },

  {
    title: "Trạng thái",
    dataIndex: "status",
    key: "status",
    render: (value: any) => <h4>{value}</h4>,
  },
  {
    title: "Ngày tạo",
    dataIndex: "created_at",
    key: "created_at",
    render: (created_at: any) => (
      <h4>{DateUtil.formatTimeDateReview(created_at)}</h4>
    ),
  },
];
const LikeMessageScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listLikeMessage, setListLikeMessage] = useState<any>([]);

  const [paging, setPaging] = useState<any>({
    page: 1,
    total: 1,
    pageSize: 10,
  });
  const [params, setParams] = useState<any>({
    page: undefined,
    from: undefined,
    to: undefined,
    text: undefined,
  });
  const getListLikeMessage = async () => {
    setIsLoading(true);
    try {
      const res = await requestGetListLikeMessage(params);
      if (res) {
        setListLikeMessage(res.data);
        setPaging({
          page: res.meta.pagination.current_page,
          total: res.meta.pagination.total_page,
          pageSize: res.data.pagination.amount_this_page,
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListLikeMessage();
  }, [params]);

  return (
    <div style={{ marginTop: 10 }}>
      <PageHeader
        title="Quản trị like"
        style={{ backgroundColor: "white", margin: "5px 10px 10px" }}
        extra={[
          <Header
            onStatusSubmit={(statusKey: string) => {
              setParams({ ...params, stutus: statusKey });
            }}
            showButton={true}
            onSearchSubmit={(input: any) => {
              setParams({ ...params, text: input });
            }}
            placeholderSearch="Nhập nội dung"
            // isSearchLoading={isSearchLoading}
            // fromDaytoDay={[params.from, params.to]}
            // dateOnSubmit={(x: string, y: string) => {
            //   setParams({ ...params, from: x, to: y });
            // }}
          />,
        ]}
      />
      <div
        style={{
          backgroundColor: "white",
          margin: "0px 10px 0px",
          padding: "15px 20px",
        }}
      >
        <Table
          style={{ height: "90vh" }}
          loading={isLoading}
          dataSource={listLikeMessage}
          bordered
          rowKey={(_, index) => `${index}`}
          columns={COLUMNS_LIKE}
          expandRowByClick={true}
          scroll={{ x: "50vh" }}
          pagination={{
            ...paging,
            onChange: (page) => {
              setParams({ ...params, page });
            },
          }}
          expandable={{
            expandedRowRender: (item: any) => (
              <div style={{ backgroundColor: "white" }}>
                <Card
                  style={{
                    width: "100%",
                    backgroundColor: "#f6f9ff",
                    borderColor: "#1890ff",
                    borderTop: "none",
                  }}
                  actions={[
                    <Button
                      onClick={async () => {
                        try {
                          await requestSuccessLikeMessage(item._id);
                          showToast("Cập nhật thành công!");
                          getListLikeMessage();
                        } catch (error) {
                          console.error("Exception " + error);
                        }
                      }}
                      type="text"
                      size="large"
                      icon={<CheckCircleOutlined color="red" />}
                      style={{
                        color: "green",
                      }}
                      disabled={item.status === "Success" ? true : false}
                    >
                      Hoàn thành
                    </Button>,
                  ]}
                ></Card>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default LikeMessageScreen;
