import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HeaderComponent } from "../component/Header";
import MenuComponent from "../component/Menu";
import { ADMIN_ROUTER_PATH } from "../config/router";
import LoginScreen from "../screen/auth/LoginScreen";

export const MainNavigator = () => {
  return (
    <div>
      <HeaderComponent toggle={() => {}} />
      <MenuComponent />
    </div>
  );
};

const AppNavigator = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/" element={<MainNavigator />}>
          <Route path={ADMIN_ROUTER_PATH.HOME} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.DASH_BOARD} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.PRODUCT} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.ORDER} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.CUSTOMER} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.VIP} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.LIST_ADMIN} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.CATEGORY} element={<></>} />
          <Route path={ADMIN_ROUTER_PATH.CONFIG} element={<></>} />
          <Route
            path={`/${ADMIN_ROUTER_PATH.CUSTOMER}/${ADMIN_ROUTER_PATH.CUSTOMER_TRANSACTION}`}
            element={<></>}
          />
          <Route
            path={`/${ADMIN_ROUTER_PATH.CUSTOMER}/${ADMIN_ROUTER_PATH.CUSTOMER_ORDER}`}
            element={<></>}
          />
          <Route
            path={`/${ADMIN_ROUTER_PATH.VIP}/${ADMIN_ROUTER_PATH.LIST_PRODUCT_VIP}`}
            element={<></>}
          />
          <Route
            path={`/${ADMIN_ROUTER_PATH.CATEGORY}/${ADMIN_ROUTER_PATH.LIST_PRODUCT_VIP}`}
            element={<></>}
          />
          <Route path={`/${ADMIN_ROUTER_PATH.LIKE}`} element={<></>} />
          <Route path={`/${ADMIN_ROUTER_PATH.INVITE_CODE}`} element={<></>} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppNavigator;
