import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  FileSearchOutlined,
  SwapOutlined,
  DisconnectOutlined,
  EditOutlined,
  ApiOutlined,
  RightSquareOutlined,
  UnlockOutlined,
  FieldTimeOutlined,
  DeleteFilled,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  TimePicker,
} from 'antd';
import { time } from 'console';
import { useEffect, useState } from 'react';
import { createSearchParams, Outlet, useNavigate } from 'react-router-dom';
import R from '../../component/assets';
import {
  COLUMNS_CUSTOMER,
  convertVndToDollar,
  IsLimitedOrder,
  UNIT,
  UserBankNames,
} from '../../config/constants';
import { ADMIN_ROUTER_PATH } from '../../config/router';
import reactotron from '../../ReactotronConfig';
import { URL_IMAGE } from '../../service/ApiService';
import {
  requestAdjustmentBalance,
  requestBlockCashOut,
  requestBlockOrder,
  requestBlockProduct,
  requestConfigTimeCategory,
  requestDefrostBalance,
  requestDeleteConfigTimeCategory,
  requestDeleteCustomer,
  requestFreezeBalance,
  requestGetListCategory,
  requestGetListCustomer,
  requestGetListLevel,
  requestGetListProduct,
  requestGetListProductVip,
  requestProductMustPurchase,
  requestResetPasswordCustomer,
  requestResetTfaPasswordCustomer,
  requestSetVipCustomer,
  requestUpdateInfoCus,
  requestUpdateLimitOrder,
} from '../../service/network/Api';
import DateUtil, { moment } from '../../util/DateUtil';
import { formatPrice, showToast } from '../../util/funcUtils';
import { Header } from '../dashboard/component/Header';
import ButtonBottomModal from '../product/component/ButtonBottomModal';
const format = 'HH:mm';
const { Option } = Select;

const CustomerScreen = () => {
  const [loading, setLoading] = useState(false);
  const [listCustomer, setListCustomer] = useState([]);
  const [visible, setVisible] = useState<any>({
    type: 0,
    show: false,
  });
  const [idCustomer, setIdCustomer] = useState<any>();
  const [item, setItem] = useState<any>();
  const [input, setInput] = useState<any>();
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const LIMIT = 12;
  const navigate = useNavigate();
  const [listVip, setListVip] = useState<any>([]);
  const [loadMore, setLoadMore] = useState<any>(false);
  const [vipId, setVipId] = useState<any>();
  const [search, setSearch] = useState<any>(undefined);
  const [idProduct, setIdProduct] = useState<any>();
  const [listProduct, setListProduct] = useState<any>([]);
  const [productIds, setProductIds] = useState<any>([]);
  const [valueCommission, setValueCommission] = useState<any>();
  const [timer, setTimer] = useState<any>({
    start_time: Date.now(),
    end_time: 1,
  });
  const [numberOrder, setNumberOrder] = useState<any>({
    num: '',
    isLimited_order: IsLimitedOrder.Limited,
  });
  const [infoCus, setInfoCusr] = useState<any>({
    name: '',
    bank_full_name: '',
    bank_phone: '',
    bank_number: '',
    bank_name: '',
  });

  const getData = async (vip?: any) => {
    setLoading(true);
    let payload = {
      page: page,
      limit: LIMIT,
      from: undefined,
      to: undefined,
      search: search,
    };
    try {
      const res = await requestGetListCustomer(payload);
      if (res) {
        setLoading(false);
        let data = res.data;
        data.map((item: any) => {
          vip.map((el: any) => {
            if (el.key == item.level) return (item.levelName = el.name);
            return (item.levelName = vip[0].name);
          });
        });

        setListCustomer(data);
        setTotalPage(res.meta.pagination.total);
      }
    } catch (error) {}
  };
  const getListProduct = async (PAGE: any) => {
    setLoadMore(true);
    const payload = {
      page: PAGE,
      limit: undefined,
      from: undefined,
      to: undefined,
      name: undefined,
      sort_by: undefined,
      category_id: vipId?._id || undefined,
    };
    try {
      const res = await requestGetListProduct(payload);
      if (res) {
        setListProduct(res?.data);
      }
    } catch (error) {}
  };
  const handleBlockProduct = async () => {
    if (!vipId?._id) {
      message.warning('Vui lòng chọn danh mục!');
      return;
    }
    // if (!productIds?.length) {
    //   message.warning("Vui lòng chọn sản phẩm!");
    //   return;
    // }
    setLoading(true);
    const payload = {
      userId: idCustomer,
      body: {
        product_ids: productIds,
        category_id: vipId?._id || '',
      },
    };
    reactotron.logImportant!('requestBlockProduct', payload);
    try {
      const res = await requestBlockProduct(payload);
      if (res) {
        setLoading(false);
        showToast('Thiết lập thành công!');
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
      }
    } catch (error) {
      setLoading(false);
      showToast('Đã có lỗi xảy ra!');
    }
  };

  const handleMoney = async (id: any, type?: any) => {
    let totalMoney;
    if (!input) {
      return message.warning('Vui lòng nhâọ số tiền!');
    }
    if (isNaN(input)) {
      return message.warning('Số tiền vui lòng nhập bằng số!');
    }

    if (type == 1) {
      totalMoney = +input + item.balance;
    }
    if (type == 2) {
      if (+input > +item.balance)
        return message.warning('Số dư của khách hàng hiện không đủ để trừ!');
      totalMoney = item.balance - +input;
    }
    if (type == 5 || type == 6) {
      // if (+input > +item.balance)
      //   return message.warning("Số dư của khách hàng hiện không đủ!");
      if (type == 5 && +input > +item.balance)
        return message.warning(
          'Số tiềng đóng băng phải nhỏ hơn hoặc bằng số dư tài khoản!'
        );
      if (type == 6 && +input > +item.frozen_balance)
        return message.warning(
          'Số tiền mở đóng băng phải nhỏ hơn hoặc bằng số tiền đã đóng băng!'
        );
      totalMoney = +input;
    }
    let payload = {
      userId: id,
      body: {
        amount: totalMoney,
      },
    };
    reactotron.logImportant!('payload', payload);
    try {
      const res =
        type == 5
          ? await requestFreezeBalance(payload)
          : type == 6
          ? await requestDefrostBalance(payload)
          : await requestAdjustmentBalance(payload);
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        getData(listVip);
        setInput('');
        showToast(
          type == 5
            ? 'Đóng băng số dư thành công!'
            : type == 6
            ? 'Mở đóng băng số dư thành công'
            : 'Cộng tiền thành công!'
        );
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại');
    }
  };

  const handleResetPassword = async () => {
    if (!input) {
      message.warning('vui lòng nhập mật khẩu mới!');
      return;
    }
    let payload = {
      id: idCustomer,
      body: {
        password: input,
      },
    };
    try {
      const res = await requestResetPasswordCustomer(payload);
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        setInput('');
        showToast('Đặt lại mật khẩu thành công!');
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };
  const handleResetTfaPassword = async () => {
    if (!input) {
      message.warning('vui lòng nhập mật khẩu mới!');
      return;
    }
    let payload = {
      id: idCustomer,
      body: {
        tfa_password: input,
      },
    };
    try {
      const res = await requestResetTfaPasswordCustomer(payload);
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        setInput('');
        showToast('Đặt lại mật khẩu rút tiền thành công!');
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };
  const handleSetVipCustomer = async () => {
    if (!vipId?.key) {
      message.warning('vui lòng chọn mức vip!');
      return;
    }
    let payload = {
      userId: idCustomer,
      levelKey: vipId?.key,
    };
    try {
      const res = await requestSetVipCustomer(payload);
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        setInput('');
        showToast('Thiết lập mức Vip thành công!');
        getData(listVip);
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };
  const handleConfigTimerCategory = async () => {
    if (!vipId?._id) {
      message.warning('Vui lòng chọn danh mục!');
      return;
    }
    if (!valueCommission) {
      message.warning('Vui lòng nhập phần trăm hoa hồng!');
      return;
    }
    let payload = {
      userId: idCustomer,
      body: {
        start_time: Math.floor(new Date().getTime() / 1000.0),
        end_time: timer?.end_time
          ? Math.floor(new Date().getTime() / 1000.0) + timer?.end_time * 60
          : Math.floor(new Date().getTime() / 1000.0) + 60,
        category_id: vipId._id,
        commission: +valueCommission,
      },
    };
    reactotron.logImportant!('payload', payload);
    try {
      const res = await requestConfigTimeCategory(payload);
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        setInput('');
        showToast('Cấu hình thời gian thành công!');
        getData(listVip);
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };
  const handleSwitchStatus = async (id: any, type: any) => {
    try {
      const res =
        type == 1 ? await requestBlockCashOut(id) : await requestBlockOrder(id);
      if (res) {
        showToast('Cập nhật trạng thái thành công!');
        getData(listVip);
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };

  const getVip = async () => {
    const payload = {
      page: 1,
      limit: 1000,
      from: '',
      to: '',
      name: '',
    };
    try {
      const res = await requestGetListCategory(payload);
      if (res) {
        getData(res.data);
        setListVip(res.data);
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const handleChooseProduct = async (id: any) => {
    // reactotron.logImportant!("test");
    // return;
    let payload = {
      userId: id,
      productId: idProduct._id,
    };
    try {
      const res = await requestProductMustPurchase(payload);
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        showToast('Thiết lập sản phẩm thành công!');
        getData(listVip);
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };
  const handleDeleteConfigTimer = async () => {
    try {
      let res = await requestDeleteConfigTimeCategory({
        userId: idCustomer,
        category_id:
          item?.config_category[item?.config_category?.length - 1]?.category_id,
      });
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        showToast('Xoá cấu hình thời gian thành công!');
        getData(listVip);
        setVipId(undefined);
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };

  const handleConfigOrder = async (id: any) => {
    // reactotron.logImportant!("test");
    // return;
    let payload = {
      userId: id,
      body: numberOrder,
    };
    try {
      const res = await requestUpdateLimitOrder(payload);
      if (res) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        showToast('Thiết lập đơn hàng thành công!');
        setNumberOrder({
          ...numberOrder,
          num: '',
        });
        getData(listVip);
      }
    } catch (error) {
      showToast('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };

  const handeUpdateInfo = async (id: any) => {
    let payload = {
      userId: id,
      body: {
        name: infoCus?.name || null,
        bank_full_name: infoCus?.bank_full_name || null,
        bank_phone: infoCus?.bank_phone || null,
        bank_number: infoCus?.bank_number || null,
        bank_name: infoCus?.bank_name || null,
      },
    };
    try {
      const res = await requestUpdateInfoCus(payload);
      console.log('res', res);
      if (res?.code !== 404 && res?.statusCode !== 500) {
        setVisible({
          ...visible,
          type: 0,
          show: false,
        });
        showToast('Cập nhật thông tin khách hàng thành công!');
        setInfoCusr({
          name: '',
          bank_full_name: '',
          bank_phone: '',
          bank_number: '',
          bank_name: '',
        });
        getData(listVip);
      }
    } catch (error) {
      message.error('Đã có lỗi xảy ra! Vui lòng thử lại.');
    }
  };

  useEffect(() => {
    getVip();
  }, [page]);
  useEffect(() => {
    // getListProduct(page);
  }, [page]);
  useEffect(() => {
    if (item?.level) {
      // getListProductVip(item.level);
      return;
    }
    if (listVip?.length) {
      // getListProductVip(listVip[0].key);
      return;
    }
  }, [item]);

  useEffect(() => {
    let timeout: any;
    if (search != undefined) {
      timeout = setTimeout(() => {
        getData(listVip);
      }, 250);
      return;
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  useEffect(() => {
    // reactotron.logImportant!("item", item);
    setItem(item);
  }, [item?.product_must_purchases]);
  useEffect(() => {
    // reactotron.logImportant!("item", item);
    getListProduct(page);
  }, [vipId]);

  const renderConfigOrder = () => {
    return (
      <div>
        <label
          style={{ fontWeight: 'bolder', color: 'GrayText', marginTop: 10 }}
          children={'Số đơn bị khoá'}
        />
        <Input
          value={numberOrder.num.toString()}
          style={{ marginTop: 5, marginBottom: 10 }}
          placeholder={'Nhập số đơn bị khoá'}
          onChange={(e) => {
            setNumberOrder({
              ...numberOrder,
              num: +e.target.value,
            });
          }}
        />
      </div>
    );
  };

  const renderUpdateUserInfo = () => {
    return (
      <div>
        <div>
          <label>{'Tên cửa hàng'}</label>
          <Input
            value={infoCus.name}
            style={{ marginTop: 10 }}
            placeholder={'Nhập tên cửa hàng'}
            onChange={(e) => {
              setInfoCusr({
                ...infoCus,
                name: e.target.value,
              });
            }}
          />
        </div>
        <h3 style={{ marginTop: 15, color: 'blueviolet' }}>
          {'Thông tin thẻ ngân hàng'}
        </h3>
        <div style={{ marginTop: 10 }}>
          <label>{'Tên ngân hàng'}</label>
          <Select
            value={infoCus?.bank_name}
            allowClear
            showSearch
            placeholder={'Chọn tên ngân hàng'}
            style={{ width: '100%', marginTop: 8 }}
            onChange={(value) => {
              setInfoCusr({
                ...infoCus,
                bank_name: value,
              });
            }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {Object.values(UserBankNames).map((bank: any) => {
              return <Option value={bank}>{bank}</Option>;
            })}
          </Select>
        </div>
        <div style={{ marginTop: 10 }}>
          <label>{'Số tài khoản'}</label>
          <Input
            value={infoCus.bank_number}
            style={{ marginTop: 10 }}
            placeholder={'Nhập số tài khoản'}
            onChange={(e) => {
              setInfoCusr({
                ...infoCus,
                bank_number: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <label>{'Họ và tên'}</label>
          <Input
            value={infoCus.bank_full_name}
            style={{ marginTop: 10 }}
            placeholder={'Nhập họ và tên'}
            onChange={(e) => {
              setInfoCusr({
                ...infoCus,
                bank_full_name: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <label>{'Số điện thoại'}</label>
          <Input
            value={infoCus.bank_phone}
            style={{ marginTop: 10 }}
            placeholder={'Nhập số điện thoại'}
            onChange={(e) => {
              setInfoCusr({
                ...infoCus,
                bank_phone: e.target.value,
              });
            }}
          />
        </div>
        {/* <div style={{ marginTop: 10 }}>
          <label>{"Tên ngân hàng"}</label>
          <Input
            value={infoCus.bank_name}
            style={{ marginTop: 10 }}
            placeholder={"Nhập tên ngân hàng"}
            onChange={(e) => {
              setInfoCusr({
                ...infoCus,
                bank_name: e.target.value,
              });
            }}
          />
        </div> */}
      </div>
    );
  };

  return (
    <div style={{ marginTop: 10 }}>
      <PageHeader
        title="Khách hàng"
        style={{ backgroundColor: 'white', margin: '5px 10px 10px' }}
        extra={[
          <Header
            placeholderSearch={'Nhập mã khách hàng'}
            onSearchSubmit={(input: any) => {
              setSearch(input);
            }}
            onStatusSubmit={(statusKey: string) => {}}
            placeholderDrop={'Sắp xếp'}
            dataDropdown={[]}
          />,
        ]}
      />
      <div
        style={{
          backgroundColor: 'white',
          margin: '0px 10px 0px',
          padding: '15px 20px',
        }}
      >
        <Table
          style={{ height: '90vh' }}
          loading={loading}
          dataSource={listCustomer}
          bordered
          rowKey={(_, index) => `${index}`}
          columns={COLUMNS_CUSTOMER}
          expandRowByClick={true}
          pagination={{
            pageSize: LIMIT,
            total: totalPage,
            onChange: (page) => {
              setPage(page);
            },
          }}
          scroll={{ x: '50vh' }}
          expandable={{
            expandedRowRender: (item: any) => (
              <div style={{ backgroundColor: 'white' }}>
                <Card
                  style={{
                    width: '100%',
                    backgroundColor: '#f6f9ff',
                    borderColor: '#1890ff',
                    borderTop: 'none',
                  }}
                  actions={[
                    <div style={{}}>
                      <Col>
                        <Button
                          onClick={() => {
                            navigate({
                              pathname: `${ADMIN_ROUTER_PATH.CUSTOMER_TRANSACTION}`,
                              search: createSearchParams({
                                userId: item._id,
                              }).toString(),
                            });
                          }}
                          type="text"
                          size="large"
                          icon={<SwapOutlined />}
                          style={{
                            color: 'tomato',
                          }}
                        >
                          Lịch sử giao dịch
                        </Button>
                        ,
                        <Button
                          onClick={() => {
                            setIdCustomer(item._id);
                            setItem(item);
                            setVisible({
                              ...visible,
                              type: 4,
                              show: true,
                            });
                            setInput('');

                            setTimer({
                              ...timer,
                              end_time: 1,
                            });
                          }}
                          type="text"
                          size="large"
                          icon={<FieldTimeOutlined />}
                          style={{
                            color: 'blue',
                          }}
                        >
                          Cấu hình thời gian
                        </Button>
                        {/* <Button
                          onClick={() => {
                            setIdCustomer(item._id);
                            setItem(item);
                            setVisible({
                              ...visible,
                              type: 5,
                              show: true,
                            });
                            setInput("");
                          }}
                          type="text"
                          size="large"
                          icon={<DisconnectOutlined />}
                          style={{
                            color: "ThreeDShadow",
                          }}
                        >
                          Đóng băng số dư
                        </Button> */}
                      </Col>
                    </div>,
                    <div>
                      <Button
                        onClick={() => {
                          navigate({
                            pathname: `${ADMIN_ROUTER_PATH.CUSTOMER_ORDER}`,
                            search: createSearchParams({
                              userId: item._id,
                            }).toString(),
                          });
                        }}
                        type="text"
                        size="large"
                        icon={<FileSearchOutlined />}
                        style={{
                          color: 'blueviolet',
                        }}
                      >
                        Lịch sử đơn hàng
                      </Button>
                      <Button
                        onClick={() => {
                          setItem(item);
                          // setProductIds(
                          //   item?.product_must_purchases?.length
                          //     ? item?.product_must_purchases
                          //     : []
                          // );
                          setIdProduct(undefined);
                          setIdCustomer(item._id);

                          setVisible({
                            ...visible,
                            type: 10,
                            show: true,
                          });
                        }}
                        type="text"
                        size="large"
                        icon={<RightSquareOutlined />}
                        style={{
                          color: '#42032C',
                        }}
                      >
                        Khoá mua sản phẩm
                      </Button>
                      {/* <Button
                        onClick={() => {
                          setIdProduct(undefined);
                          setIdCustomer(item._id);
                          setItem(item);
                          setVisible({
                            ...visible,
                            type: 7,
                            show: true,
                          });
                          setInput("");
                        }}
                        type="text"
                        size="large"
                        icon={<RightSquareOutlined />}
                        style={{
                          color: "#42032C",
                        }}
                      >
                        Sản phẩm kế tiếp
                      </Button> */}
                    </div>,
                    // <Col>
                    //   <Button
                    //     onClick={() => {
                    //       setIdCustomer(item._id);
                    //       setItem(item);
                    //       setVisible({
                    //         ...visible,
                    //         type: 4,
                    //         show: true,
                    //       });
                    //       setInput("");
                    //     }}
                    //     type="text"
                    //     size="large"
                    //     icon={<EditOutlined color="red" />}
                    //     style={{
                    //       color: "green",
                    //     }}
                    //   >
                    //     Thiết lập Vip
                    //   </Button>
                    //   <Button
                    //     onClick={() => {
                    //       setIdCustomer(item._id);
                    //       setVisible({
                    //         ...visible,
                    //         type: 6,
                    //         show: true,
                    //       });
                    //       setInput("");
                    //       setItem(item);
                    //     }}
                    //     type="text"
                    //     size="large"
                    //     icon={<ApiOutlined />}
                    //     style={{
                    //       color: "chocolate",
                    //     }}
                    //   >
                    //     Mở đóng băng số dư
                    //   </Button>
                    // </Col>,
                    <div>
                      <Col>
                        <div>
                          <Button
                            onClick={() => {
                              setIdCustomer(item._id);
                              setItem(item);
                              setVisible({
                                ...visible,
                                type: 1,
                                show: true,
                              });
                              setInput('');
                            }}
                            type="text"
                            size="large"
                            icon={<PlusCircleOutlined color="red" />}
                            style={{
                              color: '#1890ff',
                            }}
                          >
                            Cộng tiền
                          </Button>
                        </div>
                        <div>
                          <Popconfirm
                            title={
                              'Bạn chắc chắn muốn xoá khách hàng này không?'
                            }
                            onConfirm={async () => {
                              try {
                                const res = await requestDeleteCustomer(
                                  item._id
                                );
                                if (res) {
                                  getData(listVip);
                                  showToast('Xoá khách hàng thành công!');
                                }
                              } catch (error) {
                                showToast(
                                  'Đã có lỗi xảy ra! Vui lòng thử lại.',
                                  'error'
                                );
                              }
                              // handleDelete(item);
                            }}
                            okText="Xoá"
                            cancelText="Quay lại"
                            okButtonProps={{
                              danger: true,
                              type: 'primary',
                              // loading: isShowDeleteConfirm,
                            }}
                          >
                            <Button
                              type="text"
                              size="large"
                              danger
                              icon={<DeleteFilled />}
                            >
                              Xoá
                            </Button>
                          </Popconfirm>
                        </div>
                        {/* <Button
                        onClick={() => {
                          setIdCustomer(item._id);
                          setVisible({
                            ...visible,
                            type: 8,
                            show: true,
                          });
                          setNumberOrder({
                            ...numberOrder,
                            num: "",
                          });
                        }}
                        type="text"
                        size="large"
                        icon={<UnlockOutlined />}
                        style={{
                          color: "#0F3D3E",
                        }}
                      >
                        Cấu hình đơn hàng
                      </Button> */}
                      </Col>
                    </div>,
                    <Button
                      onClick={() => {
                        setVisible({
                          ...visible,
                          type: 2,
                          show: true,
                        });
                        setInput('');
                        setIdCustomer(item._id);
                        setItem(item);
                      }}
                      type="text"
                      size="large"
                      icon={<MinusCircleOutlined color="red" />}
                      style={{
                        color: 'red',
                      }}
                    >
                      Trừ tiền
                    </Button>,
                    <Button
                      onClick={() => {
                        setVisible({
                          ...visible,
                          type: 3,
                          show: true,
                        });
                        setInput('');
                        setIdCustomer(item._id);
                        setItem(item);
                      }}
                      type="text"
                      size="large"
                      icon={
                        <img
                          src={R.images.img_reset_password}
                          style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                      }
                    >
                      Đặt lại mật khẩu
                    </Button>,
                    <Button
                      onClick={() => {
                        setVisible({
                          ...visible,
                          type: 9,
                          show: true,
                        });
                        setInput('');
                        setIdCustomer(item._id);
                        setItem(item);
                      }}
                      type="text"
                      size="large"
                      icon={
                        <img
                          src={R.images.img_reset_password}
                          style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                      }
                    >
                      Đặt lại mật khẩu rút tiền
                    </Button>,
                  ]}
                >
                  <div>
                    <Row style={{ alignItems: 'center' }}>
                      <h3
                        style={{
                          color: '#007aff',
                          flex: 1,
                        }}
                        children={'Thông tin tài khoản'}
                      />
                      <Button
                        onClick={() => {
                          setVisible({
                            ...visible,
                            type: 11,
                            show: true,
                          });
                          setInput('');
                          setIdCustomer(item._id);
                          setItem(item);
                          setInfoCusr({
                            name: item?.name,
                            bank_full_name: item?.bank?.full_name,
                            bank_phone: item?.bank?.phone,
                            bank_number: item?.bank?.bank_number,
                            bank_name: item?.bank?.bank_name,
                          });
                        }}
                        type="text"
                        size="large"
                        icon={<EditOutlined />}
                        style={{
                          color: 'blueviolet',
                        }}
                      />
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                      <Col flex={1}>
                        <h4>Mã khách hàng: {item.code}</h4>
                        <h4>Số điện thoại: {item.phone}</h4>
                        {/* <Row>
                          <h4>Khóa rút tiền: </h4>
                          <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren="Bật"
                            unCheckedChildren="Tắt"
                            checked={!item.is_block_cashout}
                            onChange={() => {
                              handleSwitchStatus(item._id, 1);
                            }}
                          />
                        </Row>{" "} */}
                        {/* <Row>
                          <h4>Khóa nhận đơn hàng: </h4>
                          <Switch
                            checked={!item.is_block_create_order}
                            style={{ marginLeft: 10 }}
                            checkedChildren="Bật"
                            unCheckedChildren="Tắt"
                            onChange={() => {
                              handleSwitchStatus(item._id, 2);
                            }}
                          />
                        </Row> */}
                      </Col>
                      <Col flex={1}>
                        <h4>Tên cửa hàng: {item.name}</h4>
                        <h4>
                          Số dư: {(formatPrice(item.balance) || 0) + UNIT}
                        </h4>
                        {/* <h4>
                          Số tiền đóng băng:{" "}
                          {(formatPrice(item.frozen_balance.toFixed(2)) || 0) +
                            UNIT}
                        </h4> */}
                      </Col>
                      <Col flex={1}>
                        {/* <h4>Cấp độ: {item.level}</h4> */}
                        <h4>
                          Ngày tạo:{' '}
                          {DateUtil.formatTimeDateReview(item.created_at)}
                        </h4>
                        {item?.reference_code ? (
                          <h4>Mã giới thiệu: {item?.reference_code}</h4>
                        ) : null}
                      </Col>
                      <Col flex={1}>
                        <h4>
                          Tên tài khoản: {item?.identifier}
                        </h4>
                      </Col>
                    </Row>
                  </div>
                  {item?.bank ? (
                    <div>
                      <h3
                        style={{
                          color: '#007aff',
                        }}
                        children={'Thông tin ngân hàng'}
                      />
                      <Row style={{ marginTop: 20 }}>
                        <Col flex={1}>
                          <h4>Tên ngân hàng: {item?.bank?.bank_name}</h4>
                          <h4>Số tài khoản: {item?.bank?.bank_number}</h4>{' '}
                          <h4>Tên tài khoản: {item?.bank?.full_name}</h4>
                          {/* <h4>
                          Số tiền đóng băng:{" "}
                          {(formatPrice(item.frozen_balance.toFixed(2)) || 0) +
                            UNIT}
                        </h4> */}
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {item?.product_must_purchase ? (
                    <div>
                      <h3
                        style={{
                          color: '#007aff',
                        }}
                        children={'Thông tin sản phẩm thiết lập  kế tiếp'}
                      />
                      <Row style={{ marginTop: 20 }}>
                        <Col flex={1}>
                          <h4>Ảnh sản phẩm:</h4>
                          <img
                            crossOrigin="anonymous"
                            alt="product"
                            src={`${URL_IMAGE}/${item.product_must_purchase.product?.image}`}
                            style={{
                              width: 150,
                              height: 150,
                              resize: 'vertical',
                            }}
                          />
                        </Col>
                        <Col flex={1}>
                          <h4>
                            Giá:{' '}
                            {formatPrice(
                              item.product_must_purchase.product?.price
                            ) || 0}{' '}
                            {UNIT}
                          </h4>
                          <h4>
                            Tên sản phẩm:{' '}
                            {item.product_must_purchase.product.name}
                          </h4>
                        </Col>
                        <Col flex={1}>
                          <h4>
                            Ngày tạo:{' '}
                            {DateUtil.formatTimeDateReview(
                              item.product_must_purchase.product.updated_at
                            )}
                          </h4>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {item?.config_category?.length ? (
                    <div>
                      <h3
                        style={{
                          color: '#007aff',
                          marginTop: 20,
                        }}
                        children={'Thông tin cấu hình thời gian'}
                      />
                      <Row style={{ marginTop: 20 }}>
                        {item?.config_category?.map((el: any) => (
                          <Col flex={1}>
                            <h4>Danh mục: {el?.category_name}</h4>
                            <h4>Hoa hồng: {el?.commission}%</h4>
                            <h4>
                              Thời gian: {''}
                              {`${(el?.end_time - el?.start_time) / 60} phút`}
                            </h4>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : null}
                </Card>
              </div>
            ),
          }}
        />
        <Modal
          onCancel={() => {
            setVisible({
              ...visible,
              type: 0,
              show: false,
            });
            setIdCustomer('');
            setItem({});
            setTimer({
              start_time: '',
              end_time: '',
            });
          }}
          maskClosable={false}
          footer={null}
          title={
            visible.type === 1
              ? 'Cộng tiền cho khách hàng'
              : visible.type === 2
              ? 'Trừ tiền của khách hàng'
              : visible.type === 3
              ? 'Đặt lại mật khẩu'
              : visible.type === 5
              ? 'Đóng băng tài khoản'
              : visible.type === 6
              ? 'Mở đóng băng tài khoản'
              : visible.type === 7
              ? 'Chọn sản phẩm'
              : visible.type === 8
              ? 'Thiết lập đơn'
              : visible.type === 9
              ? 'Đặt lại mật khẩu rút tiền'
              : visible.type === 10
              ? 'Khoá mua sản phẩm'
              : visible.type === 11
              ? 'Cập nhật thông tin khách hàng'
              : 'Cấu hình thời gian'
          }
          visible={visible.show}
        >
          {visible.type == 7 ? (
            <div
              style={{
                marginBottom: 30,
                width: 500,
              }}
            >
              <Col style={{ width: '80%' }}>
                <label
                  style={{ fontWeight: 'bolder', color: 'GrayText' }}
                  children={'Sản phẩm'}
                />
                <Select
                  value={idProduct?.name || undefined}
                  allowClear
                  placeholder={'Chọn sản phẩm'}
                  style={{ width: '100%', marginTop: 8 }}
                  onChange={(value) => {
                    setIdProduct(listProduct[value]);
                  }}
                  onPopupScroll={(event: any) => {
                    let isEndOfList =
                      event.target.scrollTop >= 0.8 * event.target.scrollHeight;
                    if (isEndOfList && page != totalPage) {
                      if (!loadMore) {
                        setPage((prev: any) => (prev = page + 1));
                        return;
                      }
                      setPage(page);
                    }
                  }}
                >
                  {listProduct.map((item: any, index: number) => {
                    return (
                      <Option value={index}>
                        {
                          <Col>
                            <h3>
                              [{index + 1}]{item.name}
                            </h3>
                            <h4 style={{ color: 'red' }}>
                              {'Giá:' + formatPrice(item.price) + UNIT}
                            </h4>
                          </Col>
                        }
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col style={{ width: '80%', marginTop: 15 }}>
                <label
                  style={{ fontWeight: 'bolder', color: 'GrayText' }}
                  children={'Giá'}
                />
                <Input
                  disabled
                  value={formatPrice(idProduct?.price || 0) + UNIT}
                  style={{ marginTop: 8 }}
                  placeholder=""
                />
              </Col>

              <Col style={{ width: '60%', marginTop: 15 }}>
                <div>
                  <label
                    style={{ fontWeight: 'bolder', color: 'GrayText' }}
                    children={'Ảnh sản phẩm'}
                  />
                </div>
                <img
                  crossOrigin="anonymous"
                  alt="product"
                  src={
                    idProduct?.image
                      ? `${URL_IMAGE}/${idProduct?.image}`
                      : R.images.img_image_empty
                  }
                  style={{
                    width: 150,
                    height: 150,
                    marginTop: 10,
                    resize: 'vertical',
                  }}
                />
              </Col>
            </div>
          ) : visible.type == 8 ? (
            renderConfigOrder()
          ) : visible.type == 11 ? (
            renderUpdateUserInfo()
          ) : (
            <div>
              <label>
                {visible.type == 3 || visible.type == 9
                  ? 'Mật khẩu mới'
                  : visible.type == 4 || visible.type == 10
                  ? 'Danh mục'
                  : 'Số tiền'}
              </label>

              {visible.type !== 4 && visible.type !== 10 ? (
                <div>
                  <Input
                    value={input}
                    style={{ marginTop: 10 }}
                    placeholder={
                      visible.type == 3 || visible.type == 9
                        ? 'Nhập mật khẩu mới'
                        : 'Nhập số tiền'
                    }
                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                  />
                  {visible.type == 5 || visible.type == 6 ? (
                    <div style={{ marginBottom: 30, marginTop: 10 }}>
                      <h4
                        children={
                          `Số dư hiện tại: ${formatPrice(item.balance) || 0}` +
                          UNIT
                        }
                      />
                      <Checkbox
                        checked={item.balance === input}
                        onChange={() => {
                          setInput(item.balance === input ? '' : item.balance);
                        }}
                      >
                        Tất cả số dư
                      </Checkbox>
                    </div>
                  ) : null}
                </div>
              ) : visible.type == 10 ? (
                <div>
                  <Col style={{ width: '80%' }}>
                    <Select
                      allowClear
                      showArrow
                      placeholder={'Chọn danh mục'}
                      style={{ width: '100%', marginTop: 8 }}
                      onChange={(value) => {
                        setVipId(listVip[value]);
                      }}
                      onPopupScroll={(event: any) => {
                        let isEndOfList =
                          event.target.scrollTop >=
                          0.8 * event.target.scrollHeight;
                        if (isEndOfList && page != totalPage) {
                          if (!loadMore) {
                            setPage((prev: any) => (prev = page + 1));
                            return;
                          }
                          setPage(page);
                        }
                      }}
                    >
                      {listVip.map((item: any, index: number) => {
                        return (
                          <Option value={index}>
                            {
                              <Col>
                                <h3>{item.name}</h3>
                              </Col>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col style={{ width: '80%', marginTop: 10 }}>
                    <label>Sản phẩm</label>
                    <Select
                      // showSearch={false}
                      // defaultValue={
                      //   productIds?.length
                      //     ? productIds?.map((itm: any) => (itm = itm?.name))
                      //     : []
                      // }
                      allowClear
                      showArrow
                      placeholder={'Chọn Sản phẩm'}
                      style={{ width: '100%', marginTop: 8 }}
                      onChange={(value) => {
                        let arrID: any = [];
                        value.map((itm: any) => {
                          arrID.push(listProduct[itm]?._id);
                        });
                        reactotron.logImportant!('arrID', arrID);
                        setProductIds(arrID);
                      }}
                      mode="multiple"
                      onPopupScroll={(event: any) => {
                        let isEndOfList =
                          event.target.scrollTop >=
                          0.8 * event.target.scrollHeight;
                        if (isEndOfList && page != totalPage) {
                          if (!loadMore) {
                            setPage((prev: any) => (prev = page + 1));
                            return;
                          }
                          setPage(page);
                        }
                      }}
                    >
                      {listProduct.map((item: any, index: number) => {
                        return (
                          <Option value={index}>
                            {
                              <Col>
                                <h3>
                                  [{index + 1}]{item.name}
                                </h3>
                                <h4 style={{ color: 'red' }}>
                                  {'Giá:' + formatPrice(item.price) + UNIT}
                                </h4>
                              </Col>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </div>
              ) : (
                <Select
                  // allowClear
                  value={
                    vipId?.name ||
                    item?.config_category[item?.config_category?.length - 1]
                      ?.category_name ||
                    'Chọn danh mục'
                  }
                  placeholder={'Chọn danh mục'}
                  style={{ width: '100%', marginTop: 8 }}
                  onChange={(value) => {
                    setVipId(listVip[value]);
                  }}
                  onPopupScroll={(event: any) => {
                    let isEndOfList =
                      event.target.scrollTop >= 0.8 * event.target.scrollHeight;
                    if (isEndOfList && page != totalPage) {
                      if (!loadMore) {
                        setPage((prev: any) => (prev = page + 1));
                        return;
                      }
                      setPage(page);
                    }
                  }}
                >
                  {listVip.map((item: any, index: number) => {
                    return (
                      <Option value={index}>
                        {
                          <Row>
                            <h3>{item.name}</h3>
                          </Row>
                        }
                      </Option>
                    );
                  })}
                </Select>
              )}
              {visible.type == 4 && (
                <div>
                  <div>
                    <Col style={{ marginTop: 10 }}>
                      <label
                        children={'Hoa hồng (VD: 0.25, 0.1, 1, 1.0, ....)'}
                      />
                      <Input
                        // value={payloadAdd.value_commission}
                        style={{ marginTop: 8 }}
                        placeholder="Nhập % hoa hồng"
                        onChange={(input) => {
                          setValueCommission(input.target.value);
                        }}
                      />
                    </Col>
                    <Row style={{ marginTop: 15, alignItems: 'center' }}>
                      <label>Thời gian:</label>
                      <InputNumber
                        style={{ marginLeft: 10 }}
                        min={1}
                        max={144000}
                        defaultValue={1}
                        onChange={(value) => {
                          setTimer({
                            ...timer,
                            end_time: value,
                          });
                        }}
                      />
                      <label style={{ marginLeft: 10 }}>phút</label>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          )}

          <ButtonBottomModal
            isLoadingButton={false}
            showActionDelete={
              !!(visible.type == 4 && item?.config_category?.length)
            }
            onDelete={handleDeleteConfigTimer}
            onCancel={() => {
              setVisible({
                ...visible,
                type: 0,
                show: false,
              });
              setIdCustomer(undefined);
              setItem({});
              setInput('');
              setTimer({ start_time: '', end_time: '' });
            }}
            onClickconfirm={() => {
              if (visible.type === 3) {
                handleResetPassword();
                return;
              }
              if (visible.type === 4) {
                // handleSetVipCustomer();
                handleConfigTimerCategory();
                return;
              }
              if (visible.type === 5) {
                handleMoney(idCustomer, visible.type);
                return;
              }
              if (visible.type === 6) {
                handleMoney(idCustomer, visible.type);
                return;
              }
              if (visible.type === 7) {
                handleChooseProduct(idCustomer);
                return;
              }
              if (visible.type === 8) {
                handleConfigOrder(idCustomer);
                return;
              }
              if (visible.type === 9) {
                handleResetTfaPassword();
                return;
              }
              if (visible.type === 10) {
                handleBlockProduct();
                return;
              }
              if (visible.type === 11) {
                handeUpdateInfo(idCustomer);
                return;
              }

              handleMoney(idCustomer, visible.type);
            }}
            text={'Xác nhận'}
          />
        </Modal>
      </div>
      <Outlet />
    </div>
  );
};

export default CustomerScreen;
